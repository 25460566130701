export const privacyContent = [
    {
        title: "Keeping your information safe",
        description: "At [United Legal Assistance](/), we are committed to ensuring that your information is safe and secure at all times."
    },
    {
        title: "Your Personal Information:",
        description: [
            {
                text: `We only gather your personal information when necessary to establish a product or service we offer.\
                
                Whether this be an insurance policy or claim; we will always ensure your information is secure.`
            },
        ]
    },
    {
        title: "Your Privacy:",
        description: [
            {
                text: `We only gather your personal information which is relevant to the product you have chosen or the service we provide.\
                
                We only store your personal information for a maximum of 6 years. For more information please visit our [Frequently Asked Questions](/faq) or our [full Privacy Policy](/full-privacy-policy).`
            },
        ]
    },
]